import React from "react"

const PrivacyPolicy = ({cssClass}) => {
    return(
        <div className={cssClass}>

<section className={`${cssClass}_section`}>
    <h2>About</h2>
    <p>We are Squirrel Learning Limited (<strong>We</strong>) a company registered in England and Wales (No. 11365884).</p>
    <p>This policy tells you how we collect and process the personal data of users of our website, products and services including computer or mobiles apps (<strong>You</strong>). Personal data means information about an individual from which that person can be identified. This excludes anonymous or anonymised data.</p>
    <p>We are the data controller responsible for your personal data. That includes schools, parents/guardians, and students. Data we process include the personal data of children (under 18 and even under 13 years of age) which we accordingly process only with the consent of the child’s school and/or parents.</p>
    <p>Where we provide a website or other finished products like videography to a school, which contains personal data, that school becomes data controller and is responsible for deciding what happens to the data. We may be joint data controller with a school in certain circumstances.</p>
    <p>This Privacy Policy supplements any other privacy or fair processing notices we may give you from time to time..</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>How we collect personal data</h2>
    <ul>
        <li>We obtain information about schools, staff, and children directly from schools and organisations who have requested our services, to enable us to deliver our programmes.</li>
        <li>In some circumstances we may also collect information directly from adults. </li>
        <li>Paper registers may be used to collect person specific information from school. These are only used where the programmes require attendance data to be retained. Where this is the case, the registers are returned to the locked filing cabinet within our office immediately following the session. Schools will be notified in advance of any programmes when registers are required to be kept. All paper registers, together with any other personal data recorded on paper, will be securely destroyed as follows: at the end of the academic year, during which the activities took place.</li>
        <li>Direct interactions with you, e.g., by email, telephone or filling in forms.</li>
        <li>Automated technologies, e.g., through apps or website cookies.</li>
        <li>Publicly available sources like Ofsted</li>
    </ul>
    <p>We are not responsible for the information that may be collected by third parties by clicking on links to third party websites, plug-ins, and applications.</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>What personal data we collect</h2>
    <div className={`${cssClass}_inset`}>
        <h3 className="h3">Schools:</h3>
        <p>Names of school staff, display name, the school or organisation they work for, their position, email address, work contact details, information relating to accreditation and course attendance (where we are providing staff training), financial details of any persons paying personally for our services, photographs, and video footage of staff members</p>
    </div>
    <div className={`${cssClass}_inset`}>
        <h3 className="h3">Pupils:</h3>
        <p>Name, school or organisation, year group and/or class, age, gender, photographs, audio, and video footage</p>
    </div>
    <div className={`${cssClass}_inset`}>
        <h3 className="h3">Parents/Guardians:</h3>
        <p>Names and contact details of parents/guardians.</p>
    </div>
    <div className={`${cssClass}_inset`}>
        <h3 className="h3">Everyone:</h3>
        <p>Details of interactions we have had with any of the above persons in relation to our products or services.</p>
    </div>
    <div className={`${cssClass}_inset`}>
        <h3 className="h3">Users of our websites and apps*:</h3>
        <p>Technical data which includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.</p>
        <p>Data collected about website users:</p>
        <p>Some or all of our sites use cookies to enable the site/application to function, to provide enhanced functionality or to enable a better understanding of how people use the website/application.</p>
        <p>You can view the cookie policy and details on each website:</p>
        <table className={`${cssClass}_table`}>
            <thead>
                <tr>
                    <th>Website</th>
                    <th>Cookie Policy Link</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td data-label="Website">squirrellearning.co.uk</td>
                    <td data-label="Link"><a href="https://squirrellearning.co.uk/privacy-policy">squirrellearning.co.uk/cookies</a></td>
                </tr>
                <tr>
                    <td data-label="Website">go-read.co.uk</td>
                    <td data-label="Link"><a href="https://go-read.co.uk/cookies">go-read.co.uk/cookies</a></td>
                </tr>
                <tr>
                    <td data-label="Website">go-write.co.uk</td>
                    <td data-label="Link"><a href="https://go-write.co.uk/cookies">go-write.co.uk/cookies</a></td>
                </tr>
                <tr>
                    <td data-label="Website">
                        <ul>
                            <li>goapps.app</li>
                            <li>goread.app</li>
                            <li>gowrite.app</li>
                        </ul>
                    </td>
                    <td data-label="Link"><a href="https://goapps.app/cookies">goapps.app/cookies</a></td>
                </tr>
            </tbody>
        </table>
        <p>Profile data which includes your username and password, purchases or orders made by you, your interests, preferences, feedback, and survey responses.</p>
        <p>How a user’s device has interacted with our website(s), including the pages accessed and links clicked, download errors, length of visits to certain pages, page interaction information, and methods used to browse away from any page.</p>
        <p>For the GoRead app: information related to the books read by pupils including frequency of reading; comments entered by teacher, parents and pupils; pupil ratings; audio of pupil reading.</p>
        <p>For the GoWrite app: the writing provided by users and the results and activity of the voting process.</p>
    </div>
    
    <p>* This includes squirrellearning.co.uk, go-read.co.uk, go-write.co.uk, goapps.app, gpread.app and gowrite.app. Our apps are web-based.</p>
    <p>We <strong>do not</strong> collect any Special Categories of personal data about you (e.g., ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, health, or biometric data). Nor do we collect any information about criminal convictions and offences.</p>
    <p>We <strong>do</strong> collect, use and share (including in exchange for payment) aggregated data like statistical or demographic data (e.g., user habits of apps) for any purpose. This is not personal data as the person the data relates to can no longer be identified.</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>How we use your personal data</h2>
    <ul>
        <li>Where it is necessary for Squirrel Learning Limited to complete our programmes and activities effectively, for example to contact you about something you have indicated an interest in, for processing payment information, administering apps, creating personalised awards or certificates. </li>
        <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests, for example data analytics, or entering users (with or without consent) into prize draws, promotions, and competitions.</li>
        <li>To comply with a legal obligation, such as health and safety obligations surrounding delivery of a programme.</li>
    </ul>
    <p>We may process your personal data for more than one lawful ground. Please contact us if you need details about the specific ground based on which we are processing your personal data in a particular instance.</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>Change of purpose</h2>
    <p>We will use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason which is compatible with the original purpose, as permitted by law. </p>
    <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>How We Store Your Personal Data</h2>
    <p>We have put in place reasonable measures to keep your personal data safe, as required by law.</p>
    <p>Hard copy records containing personal data, such as order forms and registers, are stored in locked cabinets in our registered office and are not removed off-site. Such documents, or relevant personal data on such documents, will be securely destroyed at the end of the academic year, during which the activities took place, or (if sooner) when to our knowledge the member of staff or child to whom the data pertains leaves the school or (in the case of staff members) is no longer a contact or participant in training.</p>
    <p>Electronically held personal data are held in [password-protected] cloud storage, not locally on any staff laptops, which are in any event password protected and encrypted. We do not permit the use of external hard-drives, memory sticks or other devices for storing personal data. Such data is retained during the period which the activities took place and deleted at the end of the academic year.</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>Sharing Personal Data</h2>
    <p>We do not routinely share any personal data we collect except as follows:</p>
    <ul>
        <li>With our approved subcontractors for the purpose of data processing (list below).</li>
        <li>Where you have consented, for example the data collected by apps is shared amongst schools and parents/guardians).</li>
        <li>If your communication highlighted a child protection issue, or other issue that may result in police action. In such circumstances, we may be legally obliged to act.</li>
        <li>Where necessary for us to seek professional advice such as from lawyers or insurance professionals, based in the United Kingdom.</li>
        <li>Government or regulatory bodies where required by law.</li>
        <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy. </li>
    </ul>
    <h3>Our subcontractors</h3>
    <table className={`${cssClass}_table`}>
        <thead>
            <tr>
                <th>Name</th>
                <th>Processing</th>
                <th>Weblink</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td data-label="Name">Adobe Creative Cloud *</td>
                <td data-label="Processing">Third party software for storing and editing photos of events.</td>
                <td data-label="Weblink"><a href="https://www.adobe.com/uk/creativecloud">www.adobe.com/uk/creativecloud</a></td>
            </tr>
            <tr>
                <td data-label="Name">Amazon Web Services *</td>
                <td data-label="Processing">Electronic data storage, cloud server and transmission service. Personal data is stored in EU based data centers.</td>
                <td data-label="Weblink"><a href="https://aws.amazon.com">aws.amazon.com</a></td>
            </tr>
            <tr>
                <td data-label="Name">Axe Edge Limited</td>
                <td data-label="Processing">Contracted developers who develop and enhance the Site and Apps</td>
                <td data-label="Weblink"><a href="https://axeedge.design">axeedge.design</a></td>
            </tr>
            <tr>
                <td data-label="Name">G-Suite *</td>
                <td data-label="Processing">Cloud Provider</td>
                <td data-label="Weblink"><a href="https://gsuite.google.com">gsuite.google.com</a></td>
            </tr>
            <tr>
                <td data-label="Name">Heroku *</td>
                <td data-label="Processing">Hosting, Electronic data storage, cloud server and transmission service. Hosting is in EU based data centers.</td>
                <td data-label="Weblink"><a href="https://www.heroku.com">www.heroku.com/</a></td>
            </tr>
            <tr>
                <td data-label="Name">Printondemand-worldwide</td>
                <td data-label="Processing">Print and fulfillment services</td>
                <td data-label="Weblink"><a href="https://www.printondemand-worldwide.com">www.printondemand-worldwide.com/</a></td>
            </tr>
            <tr>
                <td data-label="Name">Stripe *</td>
                <td data-label="Processing">Third Party Payment Provider for Credit Card Transactions</td>
                <td data-label="Weblink"><a href="https://stripe.com">stripe.com</a></td>
            </tr>
            <tr>
                <td data-label="Name">Squarespace *</td>
                <td data-label="Processing">Hosting, Electronic data storage, cloud server and transmission service</td>
                <td data-label="Weblink"><a href="https://www.squarespace.com/">www.squarespace.com</a></td>
            </tr>
            <tr>
                <td data-label="Name"></td>
                <td data-label="Processing"></td>
                <td data-label="Weblink"></td>
            </tr>
        </tbody>
    </table>
    <p>The subcontractors marked with an asterisk “*” in the above table are located outside of the UK and so their processing of your personal data will involve a transfer of data outside the UK. Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK. </p>
</section>

<section className={`${cssClass}_section`}>
    <h2>Requesting Access to Your Personal Data</h2>
    <p>You have various legal rights including the right to access personal data held about you, correct it or request that it be deleted at any time. Please see the <a href="https://ico.org.uk/">U.K. Information Commissioner’s Office (ICO) website</a> for more details.</p>
</section>

<section className={`${cssClass}_section`}>
    <h2>Contact</h2>
    <p>It is important that the personal data we hold about you remains accurate, please inform us of any changes to your personal data.</p>
    <p>To discuss anything in this Privacy Policy or to request access to your personal data, please contact us:</p>
    <address className="">
        <a href="mailto:privacy@goapps.app">privacy@goapps.app</a>
        <p>Squirrel Learning Limited,<br/> Technology Centre, Wolverhampton Science Park, Wolverhampton, West Midlands, England, WV10 9RU</p>
    </address>
</section>


        </div>
    )
}

export default PrivacyPolicy