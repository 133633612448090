import React from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from 'react-bootstrap';

import Layout from "../components/layout"
import PrivacyPolicy from "../components/terms/privacy-policy"
import { Header } from '../components/header'

const Privacy = () => {

    return (
        <>
            <Helmet>
                <title>GoApps&trade; - Privacy Policy</title>
                <meta name="description" content="Our Privacy Policy" />
            </Helmet>
            <Layout>
                <Header />
                <Container className="withHeader">
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <h1 className="heading mb-1">Privacy Policy</h1>
                            <h2 className="bodyText highlight mb-2">V2 - Last updated: August 2021</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <PrivacyPolicy cssClass="legal" />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default Privacy