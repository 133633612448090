import React from "react"
import { Link } from "gatsby"
import logoImg from '../images/general/logos/go-apps-full.svg';

export const Header = () => {

    return (
        <header className="header">

            <Link to="/" className="">
                <img className="header-logo" src={ logoImg } alt="Go Write&trade;" />
            </Link>

        </header>
    )
};
